import './App.css';

import Header from './layout/Header/Header';
import Footer from './layout/Footer/Footer';

import { BrowserRouter } from 'react-router-dom';
import Routes from './Routes';

function App() {
  return (
    <BrowserRouter className='container'>
      <Header className='header' />
      <Routes className='main'/>
      <Footer className='footer' />
    </BrowserRouter>


  );
}

export default App;
