import './Carousel.css';
import React, { Component } from 'react';
import {
  Carousel,
  CarouselItem,
  CarouselControl,
  CarouselIndicators,
  CarouselCaption
} from 'reactstrap';

import "bootstrap/dist/css/bootstrap.min.css";

const items = [
  {
    src: require('../../assets/img1.jpg'),
    altText: '',
    caption: ''
  },
  {
    src: require('../../assets/img2.webp'),
    altText: '',
    caption: ''
  },
  {
    src: require('../../assets/img3.jpg'),
    altText: '',
    caption: ''
  },
  // {
  //   src: require('../../assets/img4.webp'),
  //   altText: '',
  //   caption: ''
  // }
];

class SimpleSlider extends Component {
  constructor(props) {
    super(props);
    this.state = { activeIndex: 0 };
    this.next = this.next.bind(this);
    this.previous = this.previous.bind(this);
    this.goToIndex = this.goToIndex.bind(this);
    this.onExiting = this.onExiting.bind(this);
    this.onExited = this.onExited.bind(this);
  }

  onExiting() {
    this.animating = true;
  }

  onExited() {
    this.animating = false;
  }

  next() {
    if (this.animating) return;
    const nextIndex = this.state.activeIndex === items.length - 1 ? 0 : this.state.activeIndex + 1;
    this.setState({ activeIndex: nextIndex });
  }

  previous() {
    if (this.animating) return;
    const nextIndex = this.state.activeIndex === 0 ? items.length - 1 : this.state.activeIndex - 1;
    this.setState({ activeIndex: nextIndex });
  }

  goToIndex(newIndex) {
    if (this.animating) return;
    this.setState({ activeIndex: newIndex });
  }

  render() {
    const { activeIndex } = this.state;

    const slides = items.map((item) => {
      return (
        <CarouselItem
          onExiting={this.onExiting}
          onExited={this.onExited}
          key={item.src}
        >
          <img className="imgSlide"src={item.src} alt={item.altText}/>
          <CarouselCaption captionText={item.caption} captionHeader={item.caption} />
        </CarouselItem>
      );
    });

    return (
      <Carousel className='test'
        activeIndex={activeIndex}
        next={this.next}
        previous={this.previous}
      >
        <CarouselIndicators items={items} activeIndex={activeIndex} onClickHandler={this.goToIndex} />
        {slides}
        <CarouselControl className="controlLeft" direction="prev" directionText="Previous" onClickHandler={this.previous} />
        <CarouselControl className="controlRight" direction="next" directionText="Next" onClickHandler={this.next} />
      </Carousel>
    );
  }
}


export default SimpleSlider;