import { useState, useEffect } from 'react';
import './Header.css';
import Img from '../../assets/logo.png'
import { Link } from 'react-router-dom';

function Header() {
    // const [hideHeader, setHideHeader] = useState(false)
    const [active, setMode] = useState(false);
    const ToggleMode = () => {
        setMode(!active);
    }

    let Icon = active ? 'icon iconActive' : 'icon';
    let Action = active ? "menu menuOpen" : "menu menuClose"

    return (
        <header className="container_header">
            <div className='logo_header'>
                <img src={Img} alt='logo'></img>
            </div>
            <nav className='nav_header'>
                <ul className='ul_header'>
                    <li><Link className='a' to="/">Inicio</Link></li>
                    <li><Link className='a' to="/About">Sobre</Link></li>
                    <li><Link className='a' to="/Catalogo">Catalogo</Link></li>
                </ul>
            </nav>
            

            <div className="agendamento_header">
                <a href='https://api.whatsapp.com/send/?phone=5531997211794&text&type=phone_number&app_absent=0'target='_blank'>Agende seu Horário</a>
            </div>

            
                <div className={Icon} onClick={ToggleMode}>
                    <div className='hamburguer hamburguerIcon'></div>
                </div>
                <div className={Action}>
                    <div className="list">
                        <ul className="listItems">
                            <li><Link className='b' to="/">Inicio</Link></li>
                            <li><Link className='b' to="/About">Sobre</Link></li>
                            <li><Link className='b' to="/Catalogo">Catalogo</Link></li>
                            <li><Link className='b' to="https://api.whatsapp.com/send/?phone=5531997211794&text&type=phone_number&app_absent=0" target='_blank'>Agendamento</Link></li>
                        </ul>
                    </div>
                </div>
            
        </header>
    )
}

export default Header
{/* <a href='#'>Inicio</a>
<a href='#'>Quem Somos</a
<a href='#'>Localização</a> */}