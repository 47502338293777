import styles from './About.module.css';
import Video from '../../assets/apresentacao.mp4';


function About() {
    return (
        <div className={styles.container}>
            <div className={styles.corFundo}><p>me achou</p></div>
            <section className={styles.fitstSection}>
                <div className={styles.about}>
                    <h1>MAIS QUE LINDA</h1>

                    <p className={styles.text}> 
                        No Salão Mais que Linda, nós acreditamos que cada pessoa tem uma beleza única e especial. Por isso, nosso objetivo é realçar o seu potencial e valorizar o seu estilo.
                        <br />
                        <br />
                        Nossa equipe é formada por profissionais qualificados e experientes, que estão sempre atualizados com as últimas tendências e técnicas do mercado da beleza. Aqui você encontra um ambiente agradável e acolhedor, onde pode relaxar e se cuidar com todo o conforto e segurança.
                        <br />
                        <br />
                        Oferecemos serviços de cabelo, maquiagem, manicure, pedicure, depilação, estética facial e corporal, podologia e muito mais. Tudo pensado para deixá-la ainda mais linda e confiante
                    </p>

                </div>
                <div className={styles.diVideo}>
                    <video className={styles.evideo} src={Video}  st controls ></video>
                </div>

            </section>
        </div>
    )
}

export default About;