import styles from './Card.module.css';

function Card({ imgSrc, text, title }) {
    return (
        <div className={`${styles.card}`}>
            <img src={process.env.PUBLIC_URL + '/cardPhotos/' + imgSrc} alt="" />

            <div className={styles.intro}>
                <h4>{title}</h4>
                <p>{text}</p>
            </div>

        </div>
    )
}

export default Card