import './Main.css';

import Video from '../../assets/apresentacao.mp4';
import Card from '../../components/Card/Card';
import SimpleSlider from '../../components/SimpleSlider/Carousel';

function Main() {
    return (
        <main>
            <section className="first-section">
                <h1>MAIS QUE LINDA</h1>
                <div><video src={Video} controls autoPlay></video></div>
            </section>
            <section>
                <div className="servicos_main">

                    <h2>Nossos serviços</h2>

                    <div className="section-cards_main">
                        <Card imgSrc='sobrancelha.jpg' title='Sobrancelhas' text='Preencha as falhas e modele as suas sobrancelhas com as nossas técnicas de micropigmentação e design.' />
                        <Card imgSrc='cabelo.jpg' title='Cabelo' text='Mude o visual, hidrate e revitalize os seus fios com os nossos serviços de corte, mechas, alinhamento, argila e ozônio.' />
                        <Card imgSrc='limpeza.jpg' title='Estética' text='Descubra o segredo da beleza completa com os serviços de estética facial e corporal do nosso salão. Mime-se com tratamentos personalizados que realçam sua beleza natural de dentro para fora.' />
                        <Card imgSrc='unha.jpg' title='Unhas' text='Cuide da saúde e da beleza das suas unhas com os nossos serviços de manicure, pedicure e spa dos pés.' />
                        <Card imgSrc='massagem.jpg' title='Massagem' text='Mantenha a saúde e a beleza do seu corpo com os nossos serviços de massagem modeladora.' />
                        <Card imgSrc='drenagem.jpg' title='Drenagem Lintática' text='Revitalize e harmonize seu corpo com a Drenagem Linfática do nosso salão de beleza. Cuide da sua saúde e realce sua beleza com essa técnica suave e eficaz.' />
                        
                    </div>

                    <div className='div-button'>
                        <a href="/Catalogo" className="glow-on-hover">Saiba Mais</a>
                    </div>

                </div>
            </section>


            <section className="thrid-section">
                {/* <div className='backG'></div> */}
                <SimpleSlider className="slide"/>
                
            </section>
        </main>
    )

}

export default Main;