import styles from './Servicos.module.css';


function Servicos({ imgSrcS, text, title }) {
    return (
        <div className={styles.container}>

            <div className={styles.conteudo}>
                <h4>{title}</h4>
                <p>{text}</p>
            </div>
            <div className={styles.imagem}>
                <img src={process.env.PUBLIC_URL + '/cardPhotos/' + imgSrcS} alt="" />

            </div>
        </div>

    )
}

export default Servicos;