import styles from './Catalogo.module.css';
import Servicos from '../../components/Servicos/Servicos.jsx';

function Catalogo() {
    return (
        <div className={styles.container}>
            <h1>MAIS QUE LINDA</h1>
            <Servicos imgSrcS='sobrancelha.webp' title='Sobrancelhas' text={<ul><li>Micropigmentação de sobrancelhas</li>
            <li>Design de sobrancelha</li></ul>} />
            <Servicos imgSrcS='cabelo.jpg' title='Cabelo' text={<ul><li>Corte de cabelo simples e moderno</li>
            <li>Mechas</li><li>Tratamento capilar argiloterapia e ozônioterapia</li>
            </ul>} />
            <Servicos imgSrcS='limpeza.jpg' title='Estética' text={<ul><li>Limpeza de pele</li><li>Empina bumbum / bumbUp</li><li>Corrente aussie</li></ul>} />
            <Servicos imgSrcS='unha.jpg' title='Unhas' text={<ul><li>Alongamento de unha</li><li>Manicure e pedicure</li></ul>} />
            <Servicos imgSrcS='massagemm.jpg' title='Massagens' text={<ul>
            <li>Massagem relaxante</li>
            <li>Liberação miofascial</li>
            <li>Massagem modeladora/redutora</li>
            </ul>}/>
            <Servicos imgSrcS='drenagem.jpg' title='Drenagem' text={<ul><li>Drenagem linfática</li>
            <li>Drenagem linfática pós-operatório</li></ul>} />

        </div>
    )
}

export default Catalogo;