import './Footer.css';
import wppIcon from "../../assets/whatsapp.svg";
import localIcon from "../../assets/location.svg";
import Img from '../../assets/logo.png'


function Footer() {
    return (
        <footer>
            <div className='descricao'>
                <a href='/'>MAIS QUE LINDA</a><p>Adentre um santuário dedicado ao seu bem-estar e autoestima. Com uma equipe excepcional, localização privilegiada no coração da cidade e serviços impecáveis, é um refúgio acolhedor onde sua beleza ganha vida.</p></div>
            <div className='infos'>

                <a href='https://www.google.com/maps/place/R.+Curitiba,+942+-+Centro,+Belo+Horizonte+-+MG,+30170-126,+Brasil/@-19.920731,-43.9441252,17z/data=!3m1!4b1!4m6!3m5!1s0xa699e2efff34f7:0xafa5b5ea0b57a54b!8m2!3d-19.920731!4d-43.9419365!16s%2Fg%2F11f3nk13p0?entry=ttu'><img src={localIcon} alt='logo'></img></a>
                <h3>Centro</h3>
                <p>
                    Rua. Curitiba, 942</p>

                <a href='https://api.whatsapp.com/send/?phone=5531997211794&text&type=phone_number&app_absent=0'target='_blank'><img src={wppIcon} alt='logo'></img></a>
                <a href='tel: +5531997211794' >31 99721-1794</a>

            </div>
            <div className='logo'>
                <img src={Img} alt='logo'
                    className="logo_footer"></img>
            </div>
        </footer>
    )
}

export default Footer;