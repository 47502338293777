import React from "react";
import { Routes, Route } from 'react-router-dom';

import Main from "./layout/Main/Main.jsx";
import About from "./pages/About/About.jsx";
import Catalogo from "./pages/Catalogo/Catalogo.jsx";

export default () => {

    return (
        <Routes>
            <Route exact path="/" element={<Main />}
            >
            </Route>
            <Route exact path="/About" element={<About />}>
            </Route>
            <Route exact path="/Catalogo" element={<Catalogo />}>
            </Route>

        </Routes >
    );

}
